<div class="registration-container">
  <div class="logo">
    <img
      alt="logo"
      src="https://cdn.rentasolutions.org/images/RS_logo_transparant.png"
    />
  </div>

  <section class="container">
    <router-outlet></router-outlet>
  </section>

  <rs-footer />
</div>